<template>
    <confirm-dialog
        v-if="dialog"
        :is-open="dialog"
        borderStyle
        showHeaderBorder
        noGrayActionArea
        :fullscreen="isMobilePort"
        @closed="emitClosed('auto-renew')">
        <template v-slot:title>
            Enable Auto-renew
        </template>
        <template v-slot:default>
            <div class="py-2">
                <h3>Title</h3>
                <p class="mb-0">{{ displayDocumentTitle }}</p>
            </div>
            <div class="my-2">
                <h3>License Quantity</h3>
                <p class="mb-0">
                    {{ item.next_renewal_license}} licenses
                </p>
            </div>
            <div class="mb-4">
                <h3>Billing Frequency</h3>
                <p class="mb-0">{{ displayBillingType() }}</p>
                <p class="mb-0">
                    Auto-renews {{ formatAutoRenewDate() }} for
                    <span v-if="loadingPrice">
                        <v-progress-circular
                            indeterminate
                            :size="25"
                            color="primary">
                        </v-progress-circular>
                    </span>
                    <span v-else>${{ getPrice |  formatPrice }} (plus applicable taxes)</span>
                </p>
            </div>
            <payment-method-display
                show-alert>
            </payment-method-display>
            <p class="mb-0">
                Your subscription will auto-renew {{ formatAutoRenewDate() }}.
            </p>
        </template>
        <template v-slot:buttons>
            <v-container class="py-0 px-2">
                <v-row dense>
                    <v-col>
                        <alert-bar
                            dismissible
                            @dismiss="dismissAlert"
                            id="display_http_messages"
                            :message="message"
                            :notificationType="notifyType">
                        </alert-bar>
                    </v-col>
                </v-row>
                <v-row justify="end">
                    <v-col cols="auto">
                        <v-btn
                            class="mr-2 font-weight-bold"
                            outlined
                            @click="emitClosed()">
                            Cancel
                        </v-btn>
                        <v-btn
                            @click="submitAndPay"
                            color="accent2"
                            depressed
                            class="font-weight-bold"
                            :disabled="loadingSubmit"
                            :loading="loadingSubmit">
                            Submit
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </confirm-dialog>
</template>

<script>
    import AlertBar from '../../notifications/AlertBar.vue';
    import ConfirmDialog from '../../ConfirmDialog.vue';
    import subscriptionChangeMixin from './../subscription-update/subcriptionChange.mixin';
    import Mobile from '../../inc/mixins/mobile';
    import { BillingApi } from '../../../api/BillingApi';
    import PaymentMethodDisplay from "../PaymentMethodDisplay.vue";
    import Moment from 'moment';

    export default {
        components: {PaymentMethodDisplay, AlertBar, ConfirmDialog},
        data() {
            return {
            }
        },
        mixins: [
            Mobile,
            subscriptionChangeMixin
        ],
        props: {
            shopUrl: {
                required: true,
                type: String
            },
            userId: {
                type: String,
                required: false
            }
        },
        computed: {

        },
        methods: {
            submitAndPay() {
                this.loadingSubmit = true;
                const request = { subscription_id: Number(this.item.subscription_id) };

                BillingApi.enableAutoRenew(request)
                    .then((response) => {
                        const { success, message } = response.data;
                        if (success) {
                            const successMessage = `Success! Your Premium subscription will auto-renew ${this.formatAutoRenewDate()}.`;

                            EventBus.fire('sync-billing-page', false);
                            this.dialog = false;
                            this.setBillingUpdateMessage(successMessage);
                            this.logEnableAutoRenew('success', successMessage);
                        } else {
                            this.handleError(message);
                        }
                    })
                    .catch((error) => {
                        const errorMessage = error.response ? 'Something went wrong.' : 'You are currently offline.';
                        this.handleError(errorMessage);
                    })
                    .finally(() => {
                        this.loadingSubmit = false;
                    });
            },

            handleError(message) {
                this.logEnableAutoRenew('failed', message);
                this.notifyUser({ success: false, message });
            },

            logEnableAutoRenew(status, message) {
                const request = {
                    orderId: this.item.parent_increment_id,
                    subscriptionId: this.item.subscription_id,
                    documentId: this.item.documentId,
                    actionTriggered: 'Auto-renew Subscription',
                    renewalDate:  Moment(this.item?.expiration).format('ll'),
                    renewalDuration: this.getRenewalDuration(this.item),
                    status: status,
                    message: message
                };
                BillingApi.logSubscriptionChange(request);
            },

            getRenewalDuration(item) {
                const subscriptionLabels = {
                    'M': 'Monthly',
                    'B': 'Annually'
                };
                return subscriptionLabels[item.subscription_type] || '3-Year';
            }
        },
        created(){
            EventBus.listen('enable-auto-renew-dialog', (data) => {
                this.subscriptionChangeType = 'Auto renew';
                this.resetState();
                this.item = data;
                this.emptyCart();
                this.setFrequency(data.next_renewal_subscription_type);
                this.setQuantity(data.next_renewal_license);
                this.getDocInfoWithPrice(data.sku);
            });
        }
    }
</script>
