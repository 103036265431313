var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-list",
        { staticClass: "py-0", attrs: { role: "menu" } },
        [
          !_vm.isAuthenticated
            ? _c(
                "v-list-item",
                {
                  staticClass: "list-item",
                  attrs: { target: "_blank", href: _vm.getRoute("signup") },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center", dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-icon", { staticClass: "primary--text" }, [
                            _vm._v(
                              "\n                        mdi-account-plus\n                    "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "primary--text" },
                            [
                              _vm._v(
                                "\n                        Register\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._l(_vm.profiles, function (profile, key) {
                return [
                  _c(
                    "v-list-item",
                    {
                      key: key,
                      staticClass: "list-item",
                      class: profile.is_active && _vm.getProfileColor(profile),
                      on: {
                        click: function ($event) {
                          return _vm.setUserProfile(profile)
                        },
                      },
                    },
                    [
                      _c(
                        "v-row",
                        {
                          class: {
                            "white--text":
                              profile.is_active &&
                              profile.name.toLowerCase() === "basic",
                          },
                          attrs: { dense: "", align: "center" },
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c("profile-icon", {
                                attrs: { profile: profile },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-col", [
                            _c("p", { staticClass: "mb-0 font-weight-bold" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(profile.name) +
                                  "\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-uppercase font-weight-regular mb-0 profile-type",
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(profile.type) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          profile.is_active
                            ? _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        "x-small": "",
                                        color: `${_vm.getProfileColor(
                                          profile
                                        )} lighten-1`,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Active\n                        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              }),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _vm.isAuthenticated &&
          !_vm.activeConcurrentProfile &&
          _vm.isMobilePort
            ? _c(
                "v-list-item",
                {
                  staticClass: "list-item",
                  attrs: { href: _vm.getRoute("cart_page") },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center", dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _vm.getItemsCount !== 0
                            ? [
                                _c(
                                  "v-badge",
                                  {
                                    staticClass: "mt-1 pt-1",
                                    attrs: { color: "accent2", right: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "badge",
                                          fn: function () {
                                            return [
                                              _c("span", {
                                                staticClass: "caption",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.getItemsCount
                                                  ),
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      472821590
                                    ),
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "primary" } },
                                      [_vm._v("shopping_cart")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _c("v-icon", { attrs: { color: "primary" } }, [
                                  _vm._v(
                                    "\n                            shopping_cart\n                        "
                                  ),
                                ]),
                              ],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "primary--text" },
                            [
                              _vm._v(
                                "\n                        Shopping Cart\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-list-item",
            {
              staticClass: "list-item",
              attrs: { target: "_blank" },
              on: {
                click: function ($event) {
                  return _vm.showConcurrentModal()
                },
              },
            },
            [
              _c(
                "v-row",
                { attrs: { align: "center", dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("v-icon", { staticClass: "primary--text" }, [
                        _vm._v(
                          "\n                        supervised_user_circle\n                    "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "primary--text" },
                        [
                          _vm._v(
                            "\n                        Enter Concurrent Code\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isAuthenticated && _vm.isPreferencesPageEnabled()
            ? _c(
                "v-list-item",
                {
                  staticClass: "list-item",
                  attrs: { href: _vm.getRoute("dashboard_account") },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center", dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-icon", { staticClass: "primary--text" }, [
                            _vm._v(
                              "\n                        mdi-account-circle-outline\n                    "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "primary--text" },
                            [
                              _vm._v(
                                "\n                        Account\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAuthenticated && _vm.isBillingPageEnabled()
            ? _c(
                "v-list-item",
                {
                  staticClass: "list-item",
                  attrs: { href: _vm.getRoute("billing_page") },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center", dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-icon", { staticClass: "primary--text" }, [
                            _vm._v(
                              "\n                        mdi-credit-card-outline\n                    "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "primary--text" },
                            [
                              _vm._v(
                                "\n                        Billing\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAuthenticated && _vm.isEnterpriseAdmin
            ? _c(
                "v-list-item",
                {
                  staticClass: "list-item",
                  attrs: { href: _vm.getRoute("enterprise_account_overview") },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center", dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-icon", { staticClass: "primary--text" }, [
                            _vm._v(
                              "\n                        mdi-office-building-cog-outline\n                    "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "primary--text" },
                            [
                              _vm._v(
                                "\n                        Enterprise Account\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAuthenticated
            ? _c(
                "v-list-item",
                {
                  staticClass: "list-item",
                  attrs: { href: _vm.getRoute("license_configuration") },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center", dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-icon", { staticClass: "primary--text" }, [
                            _vm._v(
                              "\n                        mdi-license\n                    "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "primary--text" },
                            [
                              _vm._v(
                                "\n                        Licenses\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isMobilePort
            ? _c(
                "v-list-item",
                {
                  staticClass: "list-item",
                  attrs: { target: "_blank" },
                  on: {
                    click: function ($event) {
                      return _vm.showUpdates()
                    },
                  },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center", dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-icon", { staticClass: "primary--text" }, [
                            _vm._v("mdi-bullhorn"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "primary--text" },
                            [
                              _vm._v(
                                "\n                        Recent Updates\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _vm.isActiveAdmin()
            ? [
                _c(
                  "v-list-item",
                  { attrs: { href: _vm.getRoute("admin_dashboard") } },
                  [
                    _c(
                      "v-row",
                      { attrs: { dense: "", align: "center" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("v-icon", { staticClass: "primary--text" }, [
                              _vm._v(
                                "\n                            mdi-crown\n                        "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-list-item-title",
                              { staticClass: "primary--text" },
                              [
                                _vm._v(
                                  "\n                            Admin\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.showEasyAdminMenu()
            ? [
                _c(
                  "v-list-item",
                  { staticClass: "list-item", attrs: { href: "/easyadmin" } },
                  [
                    _c(
                      "v-row",
                      { attrs: { dense: "", align: "center" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "primary--text mt-n1" },
                              [
                                _vm._v(
                                  "\n                            mdi-shield-crown-outline\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-list-item-title",
                              { staticClass: "primary--text" },
                              [
                                _vm._v(
                                  "\n                            EasyAdmin\n                            "
                                ),
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "mb-1",
                                    attrs: {
                                      small: "",
                                      dark: "",
                                      color: "blue",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                beta\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isAuthenticated
            ? _c(
                "v-list-item",
                { staticClass: "list-item", on: { click: _vm.goLogout } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-icon", { staticClass: "primary--text" }, [
                            _vm._v(
                              "\n                        mdi-exit-to-app\n                    "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "primary--text" },
                            [
                              _vm._v(
                                "\n                        Sign Out\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }