<template>
    <v-card
        :height="isMobilePort ? 'auto' : '270'"
        outlined>
        <h3 class="pl-4 pt-3 title font-weight-bold">
            Your Active Profile: {{ currentUserProfile }}
        </h3>
        <v-card-title class="font-weight-bold subtitle-1">
            <access-icon
                v-if="showPremiumIcon"
                accessLevel="premiumActive">
            </access-icon>
            {{ premiumTier }}
        </v-card-title>
        <v-card-text class="font-weight-medium text-wrap">
            <p>{{ activeTierMessage }}</p>
        </v-card-text>
        <v-card-text class="font-weight-medium text-wrap" v-if="'' !== tierAssignmentsMessage">
            <p class="font-italic" v-html="tierAssignmentsMessage"></p>
        </v-card-text>
        <v-card-actions v-if="isFreeTrialUser" class="mt-1" :class="isMobilePort && 'mb-3'">
            <v-btn
                depressed
                color="accent2"
                class="ml-1 mr-2 font-weight-medium mt-1"
                @click="goToRoute('collections_landing', {documentSlug: 'premium-complete'})">
                Subscribe to Premium Complete
             </v-btn>
             <v-btn outlined
                color="primary"
                class="font-weight-medium mt-1"
                @click="handleCancelTrial()">
                Cancel Trial
             </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import AccessIcon from "../icons/AccessIcon.vue";
    import Mobile from "../inc/mixins/mobile";
    import {ApiService} from "../../api/ApiService";
    import Moment from 'moment';
    export default {
        data () {
            return {};
        },
        mixins:[Mobile],
        components: {AccessIcon},
        props: {
            userPremiumSubscriptions: {
                required: true,
                type: Array
            },
            remoteProfile: {
                required: false,
                type: Object
            },
            userId: {
                type: String,
                required: false
            }
        },
        computed: {
            profileName: function() {
                return this.remoteProfile ?
                       this.remoteProfile.name :
                       this.personalProfileName;
            },
            premiumTier() {
                let profile = this.remoteProfile ?
                    this.remoteProfile :
                    this.activeProfile;

                let premiumTier = this.profileName;

                if('concurrent' === profile.type || 'enterprise' === profile.type) {
                    if('' === this.currentSubscriptionType) {
                        premiumTier = 'Basic';
                    } else {
                        premiumTier = 'Premium ' + this.ucWord(this.currentSubscriptionType);
                    }
                }

                return premiumTier;
            },
            isFreeTrialUser() {
                return this.premiumTier === this.$getConst("userTier").trial;
            },
            showPremiumIcon() {
                const allowedProfileNames = [
                    this.$getConst("userTier").single,
                    this.$getConst("userTier").collection,
                    this.$getConst("userTier").complete,
                    this.$getConst("userTier").staff,
                    this.$getConst("userTier").trial
                ];

                return allowedProfileNames.includes(this.premiumTier);
            },
            getTrialEndDate() {
                let profile = this.remoteProfile ? this.remoteProfile : this.activeProfile;
                return Moment(profile.free_trial_info.trial_end_date).format('ll');
            },
            activeTierMessage() {
                switch (this.premiumTier) {
                    case this.$getConst("userTier").staff:
                        return "As an ICC staff member, your Premium tier includes access to all contents and features available on the Digital Codes platform.";
                    case this.$getConst("userTier").complete:
                        return "With your Premium Complete tier, you have access to all titles within the Complete subscription along with enhanced features across those titles.";
                    case this.$getConst("userTier").single:
                    case this.$getConst("userTier").collection:
                        return `With your ${this.premiumTier} tier, you have access to Premium features across the active individual titles listed below.`;
                    case this.$getConst("userTier").trial:
                        return `You have an active 14 day free trial to Digital Codes Premium Complete. Your trial will end on ${this.getTrialEndDate}. `
                                + `Subscribe to Premium Complete before the trial ends and save 15%.`;
                    case this.$getConst("userTier").basic:
                        return "You have Digital Codes Basic access. You can view full contents in read only format across titles with Basic access.";
                    default:
                        return `With your ${this.premiumTier} tier, you have access to Premium features across the active individual titles listed below.`;
                }
            },
            tierAssignmentsMessage() {
                let profile = this.remoteProfile ? this.remoteProfile : this.activeProfile;
                let assignments = profile.assignments;
                switch (profile.type) {
                    case 'concurrent':
                        return `Your subscription for the active Concurrent Profile is managed by `
                                + `${this.capitalizeName(assignments.adminFirstName)} ${this.capitalizeName(assignments.adminLastName)} (${assignments.adminEmail}).`;
                    case 'enterprise':
                        return `Your subscription for the active Enterprise Profile is managed by `
                                + `${assignments.contactFirstName} ${assignments.contactLastName} (${assignments.contactEmail}).`;
                    case 'personal':
                        if( assignments.length &&
                            this.profileName !== this.$getConst("userTier").staff &&
                            this.profileName !== this.$getConst("userTier").trial
                        ) {
                            if(1 === assignments.length &&
                               !this.hasActiveLicenses
                            ) {
                                return `The subscription for your Personal profile was assigned to you and is managed by `
                                        + `${assignments[0].assignerFirstName} ${assignments[0].assignerLastName} (${assignments[0].assignerEmail}).`;
                            } else {
                                const licenceConfigRoute = this.getLicenseConfigUrl();
                                return `Your personal profile includes subscriptions which have been assigned to you `
                                        + `but are being managed and billed to a different user. `
                                        + `<a href="${licenceConfigRoute}" target="_blank" class="text-decoration-underline">See Details</a>.`;
                            }
                        }
                        return '';
                    default:
                        return '';
                }
            },
            currentUserProfile() {
                let profile = this.remoteProfile ? this.remoteProfile : this.activeProfile;
                let profileType = profile.type[0].toUpperCase() + profile.type.slice(1);

                if('Concurrent' === profileType) {
                    profileType = profileType + ' (' + profile.assignments.accessCode + ')';
                } else if('Enterprise' === profileType) {
                    profileType = profileType + ' (' + profile.assignments.companyName + ')';
                }
                return profileType;
            },
            hasActiveLicenses() {
                if(this.userPremiumSubscriptions.length) {
                    return _.filter(this.userPremiumSubscriptions, function(o) {
                        return o.status === 'active';
                    });
                }
                return false;
            }
        },
        methods: {
            capitalizeName(name) {
                return name ? name.charAt(0).toUpperCase() + name.slice(1).toLowerCase() : '';
            },
            handleCancelTrial: function() {
                ApiService.cancelTrial().then(() => {
                    this.clearAllCache();
                    window.location = Routing.generate('trial_signup');
                });
            },
            getLicenseConfigUrl() {
                return this.userId ?
                    Routing.generate("admin_subscriptions", {userId: this.userId, 'assignedToMe': true}) :
                    Routing.generate("license_configuration", {'assignedToMe': true });
            },
            ucWord(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            }
        }
    }
</script>
