<template>
    <v-container id="toc-wrapper-container" class="toc-wrapper pa-0">
        <div class="legend">
            <v-navigation-drawer
                app
                clipped
                v-model="legend"
                :mini-variant.sync="tocMini"
                mini-variant-width="0"
                permanent
                :width="tocWidth($getConst('contentTypePdf') == documentInfo.content_type.name)"
                height="100vh"
                style="max-height: unset"
                z-index="45"
                :class="{
                    'pa-0': !this.$store.getters.getLoggedIn
                }">
                <div
                    id="chapter-wrap-header"
                    v-resize="resizeChapterPanel">
                    <div class="px-4 pt-4 pb-2 fixed">
                        <v-row dense>
                            <v-col>
                                <h4>{{ getBookTitle(documentInfo, documentInfo.is_astm ? false : true) }}</h4>
                                <title-reference
                                    v-if="isExam"
                                    :currentBookId="documentInfo.document_id"
                                    :examId="examId"
                                ></title-reference>
                            </v-col>
                            <v-col cols="auto">
                                <div class="mr-2">
                                    <favorite-icon
                                        v-if="documentInfo && isSingleTitle(documentInfo) && '' === examId"
                                        :document_id="documentInfo.document_id"
                                        :show-message="false">
                                    </favorite-icon>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row dense align="center" v-if="documentInfo && isSingleTitle(documentInfo) && '' === examId">
                            <v-col class="py-0">
                                <adoption-profile
                                    :documentInfo="documentInfo">
                                </adoption-profile>
                                <title-reference
                                    v-if="documentInfo.supports_versions"
                                    :examId="''"
                                    :document-id="documentInfo.document_id">
                                </title-reference>
                            </v-col>
                        </v-row>
                    </div>
                    <v-divider></v-divider>
                    <v-tabs
                        v-if="showTabs"
                        v-model="activeTab"
                        height="40px"
                        grow
                        color="primary"
                        class="codes-content-tabs"
                        >
                        <v-tab
                            :class="activeTab === 0 ? 'primary--text' : 'grey--darken-1'">
                            <v-icon class="pr-1" v-if="isContentRestricted(documentInfo) && '' === examId">
                                mdi-lock-outline
                            </v-icon>
                            Contents
                        </v-tab>
                        <v-tab
                            v-on:click="showUserNotes()"
                            :class="activeTab === 1 ? 'primary--text' : 'grey--darken-1'">
                            <v-icon class="pr-1" v-if="freeView">
                                mdi-lock-outline
                            </v-icon>
                            Notes
                        </v-tab>
                        <v-tab
                            v-if="isCodeInsightsFilterEnabled() && showInsightTab"
                            v-on:click="showUserNotes()"
                            :class="activeTab === 2 ? 'primary--text' : 'grey--darken-1'">
                            <v-icon class="pr-1" v-if="freeView">
                                mdi-lock-outline
                            </v-icon>
                            Insights
                        </v-tab>
                    </v-tabs>
                    <v-tabs
                        v-else
                        height="40px"
                        left
                        color="primary"
                        >
                        <v-tab
                            class="primary--text">
                            Contents
                        </v-tab>
                    </v-tabs>
                </div>
                <keep-alive>
                    <chapter-panel
                        v-if="activeTab === 0"
                        :document-info="documentInfo"
                        :exam-id="examId">
                    </chapter-panel>
                    <notes-panel
                        v-else-if="activeTab === 1"
                        :free-view="freeView"
                        :document-info="documentInfo">
                    </notes-panel>
                    <panel-insights
                        v-else-if="activeTab === 2 && isCodeInsightsFilterEnabled() && showInsightTab"
                        :codeInsightsData="codeInsightsData"
                        :free-view="freeView"
                        :document-info="documentInfo">
                    </panel-insights>
                </keep-alive>
            </v-navigation-drawer>
        </div>
        <div v-if="'' === examId">
            <context-menu>
            </context-menu>
        </div>
    </v-container>
</template>

<script>
    import ChapterPanel from './PanelChapters.vue';
    import NotesPanel from './PanelNotes.vue';
    import ContextMenu from './../partials/contextMenu';
    import TitleReference from '../partials/titleReference.vue';
    import FavoriteIcon from '../../content/favorite.vue';
    import AdoptionProfile from "./AdoptionProfile.vue";

    import IndexMixin from './mixins/index'
    import LegendNavigation from './../inc/mixins/legendNavigation';
    import PanelInsights from "./PanelInsights.vue";
    import {PremiumBonusInsightsApi} from "../../../api/PremiumBonusInsights";

    export default {
        data() {
            return {
                legend: true,
                activeTab: 0,
                current_tab: '',
                contentPremium: '1',
                codeInsightsData: {}
            }
        },
        components: {
            PanelInsights,
            ChapterPanel,
            NotesPanel,
            ContextMenu,
            TitleReference,
            AdoptionProfile,
            FavoriteIcon,
        },
        computed: {
            showInsightTab() {
                if (!_.isUndefined(this.codeInsightsData.data)
                    && !_.isEmpty(this.codeInsightsData.data.premiumContentTypes)) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        mixins: [
            IndexMixin,
            LegendNavigation
        ],
        methods: {
            resizeChapterPanel() {
                EventBus.fire('resize-chapters-panel');
            },
            async getInsights() {
                this.codeInsightsData = await PremiumBonusInsightsApi.getInsightsSidebar(this.documentInfo.document_id);
            }
        },
        mounted() {
            this.getInsights();
        }
    }
</script>

<style lang="scss">
    #toc-wrapper-container {
        .chapter-wrapper {
            overflow-y: scroll;
        }

    }
    .toc-wrapper {
        .mini-text {
            font-size: 10px;
        }
        .v-tabs {
            .v-tabs__container {
                height: auto;
            }
            .active-tab-border {
                border: 1px solid #EEEEEE!important;
            }
            .v-tab{
                font-weight: 600 !important;
                letter-spacing: normal;
            }
        }
    }
    #chapter-wrap-header{
        .v-slide-group__prev,.v-slide-group__next{
            display: none;
        }
        .v-tab--active{
            background: unset !important;
        }
    }
    .codes-content-tabs {
        .v-tab:hover, .v-tab:focus {
            &:before {
                opacity: .46 !important;
            }
            &:not(.v-tab--active) {
                color: rgb(15, 15, 15) !important;
                >.v-btn {
                    color: rgb(15, 15, 15) !important;
                }
                >.v-icon {
                    color: rgb(15, 15, 15) !important;
                }
            }
        }
        .v-tab--active:hover, .v-tab--active:focus {
            &:before {
                opacity: .12 !important;
            }
            font-weight: 800 !important;
        }
    }
</style>
