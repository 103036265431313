var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialog
    ? _c("confirm-dialog", {
        attrs: {
          "is-open": _vm.dialog,
          borderStyle: "",
          showHeaderBorder: "",
          noGrayActionArea: "",
          fullscreen: _vm.isMobilePort,
        },
        on: {
          closed: function ($event) {
            return _vm.emitClosed("auto-renew")
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function () {
                return [_vm._v("\n        Enable Auto-renew\n    ")]
              },
              proxy: true,
            },
            {
              key: "default",
              fn: function () {
                return [
                  _c("div", { staticClass: "py-2" }, [
                    _c("h3", [_vm._v("Title")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(_vm._s(_vm.displayDocumentTitle)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "my-2" }, [
                    _c("h3", [_vm._v("License Quantity")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.item.next_renewal_license) +
                          " licenses\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-4" }, [
                    _c("h3", [_vm._v("Billing Frequency")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(_vm._s(_vm.displayBillingType())),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        "\n                Auto-renews " +
                          _vm._s(_vm.formatAutoRenewDate()) +
                          " for\n                "
                      ),
                      _vm.loadingPrice
                        ? _c(
                            "span",
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  indeterminate: "",
                                  size: 25,
                                  color: "primary",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(_vm._f("formatPrice")(_vm.getPrice)) +
                                " (plus applicable taxes)"
                            ),
                          ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("payment-method-display", { attrs: { "show-alert": "" } }),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      "\n            Your subscription will auto-renew " +
                        _vm._s(_vm.formatAutoRenewDate()) +
                        ".\n        "
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "buttons",
              fn: function () {
                return [
                  _c(
                    "v-container",
                    { staticClass: "py-0 px-2" },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("alert-bar", {
                                attrs: {
                                  dismissible: "",
                                  id: "display_http_messages",
                                  message: _vm.message,
                                  notificationType: _vm.notifyType,
                                },
                                on: { dismiss: _vm.dismissAlert },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { justify: "end" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2 font-weight-bold",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.emitClosed()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        Cancel\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "font-weight-bold",
                                  attrs: {
                                    color: "accent2",
                                    depressed: "",
                                    disabled: _vm.loadingSubmit,
                                    loading: _vm.loadingSubmit,
                                  },
                                  on: { click: _vm.submitAndPay },
                                },
                                [
                                  _vm._v(
                                    "\n                        Submit\n                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          600178386
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }