var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grey lighten-5 container-height" },
    [
      _c("v-card", { staticClass: "pb-4", attrs: { outlined: "" } }, [
        _c("h1", { staticClass: "content-container pt-3 pl-4" }, [
          _vm._v("Billing"),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "v-container",
            { staticClass: "content-container" },
            [
              _c("alert", {
                staticClass: "mt-4",
                attrs: {
                  message: _vm.subscriptionNotification.message,
                  dismissable: _vm.subscriptionNotification.dismissable,
                  showLink: _vm.subscriptionNotification.show_link,
                  linkUrl: _vm.subscriptionNotification.link_url,
                  linkText: _vm.subscriptionNotification.link_text,
                  "notification-type": "warning",
                },
              }),
              _vm._v(" "),
              _c("alert", {
                attrs: {
                  id: "display_http_messages",
                  message: _vm.message,
                  notificationType: _vm.notifyType,
                  dismissible: "",
                },
                on: { dismiss: _vm.dismissAlert },
              }),
              _vm._v(" "),
              _vm.isDataLoaded
                ? _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: _vm.isMobilePort && 12 } },
                            [
                              _c("active-licenses", {
                                attrs: {
                                  userPremiumSubscriptions:
                                    _vm.userPremiumSubscriptions,
                                  remoteProfile: _vm.remoteProfile,
                                  userId: _vm.userid,
                                  shopurl: _vm.shopurl,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: _vm.isMobilePort && 12 } },
                            [
                              _c("active-tier", {
                                attrs: {
                                  userPremiumSubscriptions:
                                    _vm.userPremiumSubscriptions,
                                  remoteProfile: _vm.remoteProfile,
                                  userId: _vm.userid,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("premium-subscriptions", {
                        attrs: {
                          userPremiumSubscriptions:
                            _vm.userPremiumSubscriptions,
                          remoteProfile: _vm.remoteProfile,
                          userId: _vm.userid,
                          activateTab: _vm.activateTab,
                        },
                      }),
                      _vm._v(" "),
                      _c("subscription-update", {
                        attrs: { userId: _vm.userid, "shop-url": _vm.shopurl },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }