var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("license-quantity-update", {
        attrs: { userId: _vm.userId, "shop-url": _vm.shopUrl },
      }),
      _vm._v(" "),
      _c("billing-frequency-update", {
        attrs: { userId: _vm.userId, "shop-url": _vm.shopUrl },
      }),
      _vm._v(" "),
      _c("enable-auto-renew", {
        attrs: { userId: _vm.userId, "shop-url": _vm.shopUrl },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }