var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isGuestUser && !_vm.preferenceLoaded
        ? _c("v-skeleton-loader", {
            staticClass: "title-topic-icon",
            attrs: { type: "image" },
          })
        : [
            !_vm.title.is_astm
              ? [
                  !_vm.showTitleIcon
                    ? _c("img", {
                        staticClass: "book-cover rounded-lg",
                        class: [
                          {
                            "image-border":
                              !_vm.width &&
                              _vm.isPremiumSubscribed(_vm.title.sku) &&
                              !_vm.isTrialExcluded(_vm.title),
                            "image-no-border":
                              !_vm.width &&
                              _vm.isPremiumSubscribed(_vm.title.sku) &&
                              _vm.isTrialExcluded(_vm.title),
                            collectionImageWidthHeight:
                              !_vm.width && _vm.title.is_collection,
                            singleImageWidthHeight:
                              !_vm.width && !_vm.title.is_collection,
                          },
                          _vm.additionalClasses,
                        ],
                        style: _vm.additionalStyles,
                        attrs: {
                          alt: _vm.title.display_title,
                          width: _vm.width,
                          src: _vm.asset(_vm.title.image_url),
                        },
                        on: {
                          error: function ($event) {
                            return _vm.asset(
                              "../../../images/coming_soon_cover.png"
                            )
                          },
                        },
                      })
                    : [
                        _vm.displayOnlyTopicIcon
                          ? [
                              _c("img", {
                                class: _vm.additionalClasses,
                                style: `filter: ${_vm.filter}; max-height: 50px;`,
                                attrs: {
                                  alt: _vm.title.title_topic.icon,
                                  width: _vm.width,
                                  src: _vm.asset(
                                    `${_vm.title.title_topic.icon}`
                                  ),
                                },
                                on: {
                                  error: function ($event) {
                                    return _vm.asset(
                                      "../../../images/coming_soon_cover.png"
                                    )
                                  },
                                },
                              }),
                            ]
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "rounded-lg pa-2 text-center title-topic-icon",
                                style: `background-color: ${_vm.title.title_topic.background_color};`,
                              },
                              [
                                _c("img", {
                                  style: `filter: ${_vm.filter}; max-height: 50px;`,
                                  attrs: {
                                    alt: _vm.title.title_topic.icon,
                                    src: _vm.asset(
                                      `${_vm.title.title_topic.icon}`
                                    ),
                                  },
                                  on: {
                                    error: function ($event) {
                                      return _vm.asset(
                                        "../../../images/coming_soon_cover.png"
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "mb-0 mt-n1 caption font-weight-bold text-truncate",
                                    style: `color: ${_vm.title.title_topic.icon_color}; word-wrap: break-word;`,
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.title.title_topic.name) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                      ],
                ]
              : _vm.title.is_astm && _vm.title.astm_subject
              ? _c(
                  "div",
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "rounded-lg",
                        class: [
                          {
                            "image-border":
                              !_vm.width &&
                              _vm.isPremiumSubscribed(_vm.title.sku) &&
                              !_vm.isTrialExcluded(_vm.title),
                            singleImageWidthHeight:
                              !_vm.width && !_vm.title.is_collection,
                          },
                          _vm.additionalClassesAstm,
                        ],
                        style: _vm.additionalStyles,
                        attrs: {
                          dark: "",
                          flat: "",
                          color: _vm.title.astm_subject.color,
                          href: _vm.title.link,
                          width: _vm.width,
                          "max-width": _vm.thumbnail ? _vm.width : "190px",
                        },
                      },
                      [
                        _c(
                          "v-card-text",
                          {
                            staticClass:
                              "astm_card d-flex flex-column d-flex justify-space-between",
                            class: {
                              "px-1 py-2": _vm.thumbnail,
                            },
                          },
                          [
                            _c(
                              "v-row",
                              {
                                class: {
                                  "pb-0": !_vm.thumbnail && !_vm.minHeight,
                                },
                              },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "py-0" },
                                  [
                                    _vm.thumbnail
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "caption white--text mb-0",
                                          },
                                          [
                                            _vm._v(
                                              "\n                            ASTM\n                        "
                                            ),
                                          ]
                                        )
                                      : [
                                          _c(
                                            "h3",
                                            {
                                              staticClass:
                                                "font-weight-regular white--text",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(_vm.splitTitle()) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "font-weight-regular white--text mb-2",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(_vm.splitTitle(true)) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _vm.title.astm_subject.icon
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0",
                                        attrs: { cols: "auto" },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              small: _vm.thumbnail,
                                              medium: !_vm.thumbnail,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.title.astm_subject.icon
                                                ) +
                                                "\n                        "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            !_vm.thumbnail
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("truncated-paragraph", {
                                          attrs: {
                                            element: "h3",
                                            additionalClass:
                                              "font-weight-regular white--text caption",
                                            text: _vm.title.subtitle,
                                            maxLength: _vm.displayTextCount,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }