<template>
    <v-row align="center">
        <v-col class="header-search-box">
            <search-bar/>
        </v-col>
    </v-row>
</template>

<script>
    import SearchBar from './SearchBar.vue';

    export default {
        components: {
            SearchBar
        }
    }
</script>
<style lang="scss" scoped>
.header-search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
</style>
