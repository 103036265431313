var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grey lighten-5 container-height" },
    [
      _c(
        "v-container",
        { class: !_vm.isMobileAndTablet ? "algin-center justify-center" : "" },
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "grey lighten-5",
                      attrs: {
                        flat: "",
                        width: _vm.isMobileAndTablet ? 800 : 500,
                      },
                    },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "justify-center" },
                        [
                          _c("v-img", {
                            attrs: {
                              "max-width": "250px",
                              height: "95px",
                              alt: "ICC Digital Codes Premium Enterprise Home",
                              src: _vm.asset(
                                require("../../../images/enterprise/ent-premium-blue-logo.png"),
                                "default"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-title",
                        { staticClass: "headline font-weight-bold" },
                        [
                          _vm._v(
                            "\n                    Sign In\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-card-subtitle", [
                        _vm._v(
                          "\n                        Welcome  " +
                            _vm._s(_vm.inviteFirstname) +
                            ", you have been invited to "
                        ),
                        _c("br"),
                        _vm._v("\n                        join "),
                        _c("b", [_vm._v(_vm._s(_vm.inviteEnterprise))]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-subtitle",
                        { staticClass: "subtitle-1 font-weight-black" },
                        [
                          _vm._v(
                            "\n                    Enter your Email and Password\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c("Form", {
                            attrs: {
                              formAction: _vm.formAction,
                              referer: _vm.referer,
                              token: _vm.token,
                              isEnterprise: true,
                              inviteEmail: _vm.inviteEmail,
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "h4",
                            { staticClass: "font-weight-regular text-center" },
                            [
                              _vm._v(
                                "\n                        Don't have an account?\n                        "
                              ),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "text-decoration-underline font-weight-bold",
                                  attrs: { href: this.getRoute("signup") },
                                },
                                [_vm._v("Sign Up")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }