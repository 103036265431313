<template>
    <div>


    <v-app-bar
        app
        clipped-left
        clipped-right
        flat
        fixed
        dense
        id="navigation-app"
        height="64px"
        class="white elevation-1">
        <v-toolbar-items>
            <v-btn
                text
                @click="showNavDrawer()">
                <template>
                    <v-icon color="black">menu</v-icon>
                    Menu
                </template>
            </v-btn>
            <v-btn
                text
                class="primary--text"
                aria-label="Link opens home page"
                :href="getRoute('index_landing')">
                <img
                    v-if="!hasPremiumSubscription"
                    class="logo"
                    alt="ICC Digital Codes Home"
                    width="80"
                    height="42"
                    :src="asset(require('../../../images/logo_dc.png'), 'default')"
                />
                <img
                    v-else
                    class="logo"
                    alt="ICC Digital Codes Premium Home"
                    width="100"
                    height="42"
                    :src="asset(require('../../../images/icc_digital_codes_premium_100.png'), 'default')"
                />
            </v-btn>
        </v-toolbar-items>
        <search-bar
            class="mx-5">
        </search-bar>

        <cart-updates></cart-updates>
        <logo-menu></logo-menu>
        <navigation-help-menu></navigation-help-menu>

      <template v-if="!hasPremiumSubscription">

        <v-btn
            color="accent2"
            class="font-weight-bold"
            small
            depressed
            :href="getRoute(pricingPageEnabled ? 'pricing_page' : 'premium_features')"
        >
          Subscribe
        </v-btn>

      </template>

      <template>
        <v-toolbar-items>
          <profiles-menu>
          </profiles-menu>
        </v-toolbar-items>
      </template>
        <v-dialog
            v-model="userNoticeModal"
            width="600"
            :fullscreen="isMobilePort">
            <v-card>
                <v-card-title
                    id="user-notice"
                    class="primary white--text"
                    :class="{
                        'headline': !isMobilePort,
                        'caption mobile-title': isMobilePort
                    }">
                    Notice
                    <v-spacer></v-spacer>
                    <v-icon small class="mr-2 accent2--text" @click="closeNoticeModal()">close</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <p class="mb-0">
                                <b>ICC welcomes you!</b> <br />

                                By using the website, you are agree to our updated <a href="https://www.iccsafe.org/about/terms-of-use/"><b>Terms of Use</b></a>,
                                which will become effective on {{termsEffectiveDate | moment("LL")}}. <br />
                            </p>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="closeNoticeModal()">
                        Got It
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app-bar>
    <v-navigation-drawer
        v-if="showCartQue"
        disable-resize-watcher
        app
        clipped
        right
        width="420px"
        height="100vh">
        <cart-que>
        </cart-que>
    </v-navigation-drawer>
    </div>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';
    import NavigationMixin from './mixins/navigation';
    import SubscriptionIdentifier from './SubscriptionIdentifier';
    import ProfilesMenu from './ProfilesMenu';
    import SearchBar from './../../search/searchbar/index';
    import {AppParameters} from "../../../AppParameters";
    import Moment from 'moment';
    import NavigationHelpMenu from "./NavigationHelpMenu.vue";
    import CartUpdates from "./CartUpdates.vue";
    import FeedbackChat from '../../inc/mixins/feedback_chat';
    import CartQue from "../../pricing/CartQue.vue";
    import { mapGetters } from 'vuex';

    export default {
        data: function () {
            return {
                noticeStartDate: AppParameters.notice_start_date,
                noticeEndDate: AppParameters.notice_end_date,
                termsEffectiveDate: AppParameters.terms_effective_date,
                pricingPageEnabled: AppParameters.pricing_page_enabled,
                userNoticeModal: false,
                username: '',
                premiumComplete: window.location.pathname === '/dashboard/library' && 'complete' === this.$session.get('subscriptionType'),
            };
        },
        mixins: [NavigationMixin, Mobile, FeedbackChat],
        components: {
            NavigationHelpMenu,
            CartUpdates,
            SubscriptionIdentifier,
            SearchBar,
            ProfilesMenu,
            CartQue
        },
        methods: {
            showNavDrawer() {
                EventBus.fire('show-nav-drawer', true);
            },
            isNoticeEnabled() {
                let noticeStartDate = Moment(this.noticeStartDate, "YYYY-MM-DD");
                let noticeEndDate = Moment(this.noticeEndDate, "YYYY-MM-DD");
                let today = Moment(new Date(), "YYYY-MM-DD");

                return noticeStartDate <= today && noticeEndDate > today;
            },
            checkUserNotice() {
                if(this.isNoticeEnabled() && "" === this.getCookie('hasSeenModal')) {
                    this.showUserNoticeModal();
                    document.cookie = "hasSeenModal=true;expires=" + this.noticeEndDate
                  + ";domain=.iccsafe.org;path=/";
                }
            },
            getCookie(cookiename) {
                let name = cookiename + "=";
                let spli = document.cookie.split(';');

                for(var j = 0; j < spli.length; j++) {
                    let char = spli[j];
                    while (char.charAt(0) == ' ') {
                        char = char.substring(1);
                    }
                    if (char.indexOf(name) == 0) {
                        return char.substring(name.length, char.length);
                    }
                }

                return "";
            },
            showUserNoticeModal() {
                this.userNoticeModal = true;
            },
            closeNoticeModal() {
                this.userNoticeModal = false;
            },
        },
        computed: {
            ...mapGetters('PricingModule', [
                'getShowCartQue',
            ]),
            showCartQue() {
                return AppParameters.pricing_page_enabled && this.getShowCartQue;
            },
            helpLink() {
                return this.$getConst('links').help;
            }
        },
        created() {
            this.checkUserNotice();
        }
    }
</script>

<style lang="scss">
    #navigation-app {
        z-index: 200;
    }
    .v-badge__badge {
        padding: 0px 6px;
    }
    .v-badge__wrapper{
        top: 5px;
    }
    .menu-login {
        a {
            text-decoration: none;
        }
    }
    #profile-button {
        .v-btn__content {
            display: block;
        }
    }
</style>
<style lang="scss" scoped>
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .access-level {
            margin-right: 45px;
        }
    }
    .logo {
        max-width: 100px;
    }
    .rounded-0 {
        border-radius: 0!important;
    }
    .flex-fix {
        flex: auto!important;
    }
</style>
