<template>
    <v-list
        dense
        class="py-0">
        <v-list-item v-if="title">
            <v-list-item-content>
                <p class="caption mb-0 font-weight-medium">
                    {{title}}
                </p>
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="loading">
            <v-list-item-content>
                <v-progress-linear
                    indeterminate
                    color="primary">
                </v-progress-linear>
            </v-list-item-content>
        </v-list-item>
        <template v-else>
            <v-list-item
                v-if="search"
                @click="getSearchAction()">
                <v-list-item-icon class="mr-2">
                    <v-icon color="black">
                        mdi-magnify
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="black--text">
                        {{getSearchText()}}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item
                v-for="(item, key) in results"
                :key="key"
                :href="getHref(item)"
                @click="getClick(item)">

                <v-list-item-avatar v-if="showTitleTopics && item.title_topic" class="title-topic-icon mr-2">
                    <img :src="asset(item.title_topic.icon)" :alt="item.title_topic?.name || 'Title Topic'">
                </v-list-item-avatar>
                <v-list-item-icon v-else class="mr-2">
                    <v-icon color="black">
                        {{ icon }}
                    </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title
                        class="black--text"
                        v-html="getItemText(item)">
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
    import {AppParameters} from "../../../AppParameters";

    export default {
        name: 'SearchList',
        props: {
            title: {
                required: false,
                type: String
            },
            search: {
                required: false,
                type: String
            },
            results: {
                required: false,
                type: Array,
            },
            loading: {
                required: false,
                type: Boolean,
                default: true
            },
            contentKey: {
                required: false,
                type: String,
                default: 'titles'
            },
            category: {
                required: false,
                type: String
            },
            icon: {
                required: false,
                type: String,
                default: 'mdi-text-box-outline'
            },
            showUpsell: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        computed: {
            showTitleTopics() {
                return AppParameters.title_topic_display_enabled && true;
            }
        },
        methods: {
            getHref(item) {
                switch (this.contentKey) {
                    case 'titles':
                        return item.link
                }
            },
            getClick(item) {
                switch (this.contentKey) {
                    case 'search':
                        this.goToContentResults(item.sectionTitleClean)
                        break;
                }
                switch (this.contentKey) {
                    case 'recentSearches':
                        this.goToContentResults(item.sectiontitle)
                        break;
                }
                this.$emit('itemClick')
            },
            getItemText(item) {
                switch (this.contentKey) {
                    case 'titles':
                        return item.highlighted ? item.highlighted.display_title : item.display_title
                    case 'recentSearches':
                        return item.sectiontitle
                    case 'search':
                        return item.highlighted ? item.highlighted.sectionTitleClean : item.sectionTitleClean
                }
            },
            getSearchAction() {
                switch (this.contentKey) {
                    case 'titles':
                        return this.showTitleResults()
                    case 'search':
                        return this.goToContentResults(this.search)
                }
                this.$emit('itemClick')
            },
            getSearchText() {
                switch (this.contentKey) {
                    case 'titles':
                        return `See all title results for: "${ this.search }"`
                    case 'search':
                    return `Search for contents containing "${this.search}" in ${
                        this.isDocumentPage()
                            ? `${this.$store.getters.getDocumentInfo['book_year']} ${this.$store.getters.getDocumentInfo['title']}`
                            : 'My Premium Titles'
                        }`;
                }
            },
            showTitleResults() {
                this.goToRoute('sections_search_page', {
                        'section': 'titles',
                        'searchTermAny': this.search
                    });
            },
            goToContentResults(searchTerm) {
                if (!this.isActiveUser || !this.hasPremiumSubscription) {
                    return false;
                }
                this.$emit('setSearchTerm', searchTerm);

                this.$nextTick(() => {
                    if (this.searchTab === this.$getConst('contentSearchType')) {
                        EventBus.fire('update-content-search-results', searchTerm);
                    } else if(this.$store.getters.getIsDocumentPage) {
                        this.setDocumentToSearch();
                        this.redirectTocontentResultPage(searchTerm);
                    } else {
                        this.redirectTocontentResultPage(searchTerm);
                    }
                });
            },
            redirectTocontentResultPage(searchTerm) {
                this.goToRoute('sections_search_page', {
                    'section': "content",
                    'searchTermAny': searchTerm
                });
            },
            isDocumentPage(remember = false) {
                if(this.$store.getters.getIsDocumentPage) {
                    if(remember) {
                        this.setDocumentToSearch();
                    }
                    return true;
                }
                return false;
            },
            setDocumentToSearch() {
                this.$session.set(
                    'searchedDocument',
                    this.$store.getters.getDocumentInfo
                );
            }
        }
    }
</script>

<style lang="scss" scoped>
.title-topic-icon {
  width: 24px !important;
  min-width: unset !important;
  height: unset !important;
}
</style>
