var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "primary" }, [
    _c(
      "div",
      {
        staticClass: "container py-8 white--text",
        staticStyle: { "max-width": "1400px" },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "footer-top d-lg-flex justify-space-between align-center",
          },
          [
            _c(
              "div",
              { staticClass: "logo-container d-flex" },
              [
                _c("v-img", {
                  attrs: {
                    "max-width": "80px",
                    src: "/app/images/icons/logo.png",
                    alt: "ICC logo",
                    role: "img",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-lg-0 mt-3 text-lg-center" },
              [
                _c("p", [_vm._v("Subscribe to the Building Safety Journal")]),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "px-12",
                    attrs: {
                      large: "",
                      href: "https://www.iccsafe.org/about/periodicals-and-newsroom/subscribe/",
                      target: "_blank",
                    },
                  },
                  [_vm._v("\n                    Subscribe\n                ")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex mt-lg-0 mt-3" }, [
              _c("img", {
                staticClass: "cp mr-5",
                staticStyle: { "max-width": "180px" },
                attrs: {
                  alt: "Available on Google Play",
                  role: "link",
                  "aria-label": "Google Play",
                  tabindex: "0",
                  src: "/app/images/google_play.png",
                },
                on: {
                  click: function ($event) {
                    return _vm.appClick()
                  },
                  keydown: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.appClick()
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "space", 32, $event.key, [
                          " ",
                          "Spacebar",
                        ])
                      )
                        return null
                      return _vm.appClick()
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("img", {
                staticClass: "cp",
                staticStyle: { "max-width": "180px" },
                attrs: {
                  alt: "Available on App Store",
                  role: "link",
                  "aria-label": "App Store",
                  tabindex: "0",
                  src: "/app/images/apple_store.png",
                },
                on: {
                  click: function ($event) {
                    return _vm.appClick(true)
                  },
                  keydown: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.appClick(true)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "space", 32, $event.key, [
                          " ",
                          "Spacebar",
                        ])
                      )
                        return null
                      return _vm.appClick(true)
                    },
                  ],
                },
              }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "ul",
          {
            staticClass:
              "list-none pl-0 footer-links d-lg-flex justify-space-between my-8",
          },
          [
            _c("li", { staticClass: "footer-solutions" }, [
              _c("h6", { staticClass: "caption mb-0 white--text mb-3" }, [
                _vm._v(
                  "\n                    ICC Family of Solutions\n                "
                ),
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "list-none d-flex align-center mx-0 px-0" },
                _vm._l(_vm.solutions, function (solution, index) {
                  return _c("li", { key: index, staticClass: "mr-5" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: solution.link,
                          target: "_blank",
                          "aria-label": _vm.ariaLabelForUrl(solution.link),
                          title: solution.title,
                        },
                      },
                      [
                        _c("img", {
                          attrs: { src: solution.image, alt: solution.title },
                        }),
                      ]
                    ),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "footer-social mt-lg-0 mt-3" }, [
              _c("h6", { staticClass: "caption mb-0 white--text mb-3" }, [
                _vm._v(
                  "\n                    Connect With Us\n                "
                ),
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "list-none d-flex mx-0 px-0" },
                _vm._l(_vm.socialMedia, function (item, index) {
                  return _c("li", { key: index, staticClass: "mr-5" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: item.link,
                          target: "_blank",
                          "aria-label": _vm.ariaLabelForUrl(item.link),
                        },
                      },
                      [
                        _c("v-icon", { attrs: { color: "white" } }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(item.icon) +
                              "\n                            "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "footer-links mt-5" }, [
          _c(
            "ul",
            {
              staticClass: "d-lg-flex mx-0 px-0",
              staticStyle: { "list-style-type": "none" },
            },
            _vm._l(_vm.logoLinks, function (link, index) {
              return _c("li", { key: index, staticClass: "px-0" }, [
                _c(
                  "a",
                  {
                    staticClass: "caption white--text",
                    attrs: {
                      target: "_blank",
                      "aria-label": _vm.ariaLabelForUrl(link.link),
                      href: link.link,
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(link.title) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                index < _vm.logoLinks.length - 1
                  ? _c(
                      "span",
                      { staticClass: "px-8 d-none d-lg-inline-block" },
                      [_vm._v("|")]
                    )
                  : _vm._e(),
              ])
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "footer-bottom mt-8" }, [
          _c("p", { staticClass: "caption white--text mb-0" }, [
            _vm._v(
              "\n                COPYRIGHT © " +
                _vm._s(new Date().getFullYear()) +
                " INTERNATIONAL CODE COUNCIL, INC. ALL RIGHTS RESERVED.\n            "
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }