import axios from 'axios'
const client = axios.create();

export default {
    async getNotificationsDashboard() {
        return client.get(Routing.generate('get_subscription_notifications_dashboard'));
    },
    async getNotificationsBilling() {
        return client.get(Routing.generate('get_subscription_notifications_billing'));
    },
    async dismissNotification(hash) {
        return client.post(Routing.generate('dismiss_notification'), {
            hash: hash
        });
    }
}