var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grey lighten-5 container-height" },
    [
      _c("global-header", { attrs: { heading: _vm.welcomeMessage } }),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c("alert-bar", {
            staticClass: "mt-4",
            attrs: {
              message: _vm.subscriptionNotification.message,
              dismissible: _vm.subscriptionNotification.dismissable,
              showLink: _vm.subscriptionNotification.show_link,
              linkUrl: _vm.subscriptionNotification.link_url,
              linkText: _vm.subscriptionNotification.link_text,
              "notification-type": "warning",
            },
            on: { dismiss: _vm.dismissNotification },
          }),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { lg: "8" } },
                [
                  _c("trial-block"),
                  _vm._v(" "),
                  _c("outlined-card", {
                    attrs: { noPadding: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c(
                              "h2",
                              { staticClass: "text-h6 font-weight-bold" },
                              [_vm._v("Titles")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c(
                              "v-tabs",
                              {
                                attrs: { "align-tabs": "left" },
                                model: {
                                  value: _vm.activeTab,
                                  callback: function ($$v) {
                                    _vm.activeTab = $$v
                                  },
                                  expression: "activeTab",
                                },
                              },
                              _vm._l(_vm.tabs, function (tab) {
                                return _c(
                                  "v-tab",
                                  {
                                    key: tab.key,
                                    staticClass: "font-weight-bold",
                                    class:
                                      _vm.activeTab === tab.key
                                        ? ""
                                        : "grey--darken-1",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(tab.name) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c("v-divider"),
                            _vm._v(" "),
                            _c(
                              "v-container",
                              [
                                _vm.loading[_vm.tabSelected]
                                  ? _c("loading-linear")
                                  : [
                                      _c(
                                        "v-row",
                                        { attrs: { align: "center" } },
                                        [
                                          _c("v-col", [
                                            _vm.pagination[_vm.tabSelected]
                                              .totalResults
                                              ? _c(
                                                  "p",
                                                  { staticClass: "fs-16 mb-0" },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          _vm.pagination[
                                                            _vm.tabSelected
                                                          ].totalResults
                                                        ) +
                                                        " titles " +
                                                        _vm._s(
                                                          _vm.filters.search
                                                            ? `for search term ${_vm.filters.search}`
                                                            : ""
                                                        ) +
                                                        "\n                                        "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "p",
                                                  { staticClass: "fs-16 mb-0" },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.goToRoute(
                                                              "sections_search_page"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                Search codes\n                                            "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "or browse code categories below:\n                                        "
                                                    ),
                                                  ]
                                                ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _vm.tabSelected !== "favorites"
                                                ? _c("titles-filters", {
                                                    on: {
                                                      updateFilters:
                                                        _vm.switchFilters,
                                                    },
                                                  })
                                                : _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        outlined: "",
                                                        depressed: "",
                                                        href: _vm.getRoute(
                                                          "favorites_landing"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            Manage Favorites\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticStyle: { position: "relative" },
                                        attrs: { id: "filterMenu" },
                                      }),
                                      _vm._v(" "),
                                      _vm.filters.category.length ||
                                      _vm.filters.collections.length
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c(
                                                    "v-chip",
                                                    {
                                                      staticClass:
                                                        "primary--text mr-2 mb-1",
                                                      attrs: {
                                                        color: "primaryLight",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "fs-16",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    " +
                                                              _vm._s(
                                                                _vm.filters
                                                                  .category[0] ||
                                                                  _vm.filters
                                                                    .collections[0]
                                                              ) +
                                                              "\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { right: "" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.resetFilters()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                mdi-close\n                                            "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.titles[_vm.tabSelected].length
                                        ? _c("title-list", {
                                            attrs: {
                                              loading:
                                                _vm.loading[_vm.tabSelected],
                                              titles:
                                                _vm.titles[_vm.tabSelected],
                                              "items-per-page":
                                                20 *
                                                _vm.pagination[_vm.tabSelected]
                                                  .currentPage,
                                            },
                                          })
                                        : [_c("category-collections-buttons")],
                                      _vm._v(" "),
                                      _vm.titles[_vm.tabSelected].length <
                                      _vm.pagination[_vm.tabSelected]
                                        .totalResults
                                        ? _c(
                                            "v-row",
                                            { attrs: { justify: "center" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "auto" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        color: "primary",
                                                        loading:
                                                          _vm.showMoreLoading[
                                                            _vm.tabSelected
                                                          ],
                                                        disabled:
                                                          _vm.showMoreLoading[
                                                            _vm.tabSelected
                                                          ],
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.getTitles(
                                                            true
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            Show More\n                                            "
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { right: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                mdi-chevron-down\n                                            "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                              ],
                              2
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-col", { attrs: { lg: "4" } }, [_c("sidebar")], 1),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }