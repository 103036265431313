<template>
    <div>

        <v-skeleton-loader
                v-if="!isGuestUser && !preferenceLoaded"
                type="image"
                class="title-topic-icon"
        ></v-skeleton-loader>

        <template v-else>

        <template v-if="!title.is_astm">
            <img
                :alt="title.display_title"
                v-if="!showTitleIcon"
                class="book-cover rounded-lg"
                :class="[{
                'image-border': !width && isPremiumSubscribed(title.sku) && !isTrialExcluded(title),
                'image-no-border': !width && isPremiumSubscribed(title.sku) && isTrialExcluded(title),
                'collectionImageWidthHeight': !width && title.is_collection,
                'singleImageWidthHeight': !width && !title.is_collection
            }, additionalClasses]"
                :width="width"
                :style="additionalStyles"
                :src="asset(title.image_url)"
                @error="asset('../../../images/coming_soon_cover.png')"/>
            <template v-else>
                <template v-if="displayOnlyTopicIcon">
                    <img
                        :alt="title.title_topic.icon"
                        :width="width"
                        :class="additionalClasses"
                        :src="asset(`${title.title_topic.icon}`)"
                        :style="`filter: ${filter}; max-height: 50px;`"
                         @error="asset('../../../images/coming_soon_cover.png')"/>
                </template>
                <div
                    v-else
                    class="rounded-lg pa-2 text-center title-topic-icon"
                    :style="`background-color: ${title.title_topic.background_color};`">
                    <img
                        :alt="title.title_topic.icon"
                        :src="asset(`${title.title_topic.icon}`)"
                        :style="`filter: ${filter}; max-height: 50px;`"
                        @error="asset('../../../images/coming_soon_cover.png')"/>
                    <p
                        class="mb-0 mt-n1 caption font-weight-bold text-truncate"
                        :style="`color: ${title.title_topic.icon_color}; word-wrap: break-word;`">
                        {{ title.title_topic.name }}
                </p>
            </div>
            </template>


        </template>
        <div v-else-if="title.is_astm && title.astm_subject">
            <v-card
                dark
                flat
                class="rounded-lg"
                :class="[{
                    'image-border': !width && isPremiumSubscribed(title.sku) && !isTrialExcluded(title),
                    'singleImageWidthHeight': !width && !title.is_collection
                }, additionalClassesAstm]"
                :color="title.astm_subject.color"
                :href="title.link"
                :width="width"
                :style="additionalStyles"
                :max-width="thumbnail ? width : '190px'">
                <v-card-text
                    class="astm_card d-flex flex-column d-flex justify-space-between"
                    :class="{
                        'px-1 py-2': thumbnail
                    }">
                    <v-row
                        :class="{
                            'pb-0': !thumbnail && !minHeight
                        }">
                        <v-col
                            class="py-0">
                            <p
                                v-if="thumbnail"
                                class="caption white--text mb-0">
                                ASTM
                            </p>
                            <template v-else>
                                <h3 class="font-weight-regular white--text">
                                    {{ splitTitle() }}
                                </h3>
                                <p class="font-weight-regular white--text mb-2">
                                    {{ splitTitle(true) }}
                                </p>
                            </template>
                        </v-col>
                        <v-col
                            cols="auto"
                            class="py-0"
                            v-if="title.astm_subject.icon">
                            <v-icon
                                :small="thumbnail"
                                :medium="!thumbnail">
                                {{ title.astm_subject.icon }}
                            </v-icon>
                        </v-col>
                    </v-row>
                    <v-row v-if="!thumbnail">
                        <v-col>
                            <truncated-paragraph
                                element="h3"
                                additionalClass="font-weight-regular white--text caption"
                                :text="title.subtitle"
                                :maxLength="displayTextCount">
                            </truncated-paragraph>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>

        </template>

    </div>
</template>

<script>
    import {AppParameters} from "../../../AppParameters";
    import { hexToCSSFilter } from 'hex-to-css-filter';
    import TruncatedParagraph from "../../partials/TruncatedParagraph";
    import {AsyncUtils} from "../../../util/AsyncUtils";

    export default {
        name: 'TitleGraphic',
        data() {
            return {
                AppParameters,
                coverPreference: null,
                preferenceLoaded: false
            }
        },
        props: {
            title: {
                required: true,
                type: Object
            },
            width: {
                required: false,
                type: String,
                default: null
            },
            additionalClasses: {
                required: false,
                type: String
            },
            additionalStyles: {
                required: false,
                type: String
            },
            additionalClassesAstm: {
                required: false,
                type: String
            },
            thumbnail: {
                required: false,
                type: Boolean,
                default: false
            },
            minHeight: {
                required: false,
                type: String,
                default: null
            },
            displayTextCount: {
                required: false,
                type: Number,
                default: 70
            },
            overrideTitleIcon: {
                required: false,
                type: Boolean,
                default: false
            },
            overrideCover: {
                required: false,
                type: Boolean,
                default: false
            },
            displayOnlyTopicIcon: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        components: {
            TruncatedParagraph
        },
        computed: {
            isGuestUser() {
                return this.$store.getters.getUser === null;
            },
            filter() {
                const color = this.title.title_topic?.icon_color;
                return color ? hexToCSSFilter(this.title.title_topic.icon_color).filter : null;
            },
            showTitleIcon() {
                if (this.overrideCover) {
                    return true
                }
                if (this.overrideTitleIcon) {
                    return false
                }
                if (!this.AppParameters.title_topic_display_enabled && !this.AppParameters.user_preferences_page_enabled) {
                    return false
                }
                if (this.AppParameters.user_preferences_page_enabled && this.coverPreference === 'icon') {
                    return true
                }
                return this.AppParameters.title_topic_display_enabled;
            }
        },
        methods: {
            splitTitle(year = false) {
                let title = this.title.display_title.split('-')
                if (year) {
                    return title[1] ?? title[1];
                }
                return _.replace(title[0], 'ASTM ', '');
            },
            getCoverPreference() {
                AsyncUtils.awaitUntil(() => {
                    return this.$store.getters.getUser !== null;
                }, 200).then(() => {
                    const user = this.$store.getters.getUser;

                    const pk = this.$getConst('preferenceKey');
                    // new style is called "icon". Legacy = "cover"
                    this.coverPreference = user.getPreference(pk, "cover");
                    this.preferenceLoaded = true;
                });
            }
        },
        created() {
            this.getCoverPreference();
        }
    }
</script>

<style lang="scss" scoped>
    .image-border {
        border: 4px solid #FFA201!important;
    }
    .collectionImageWidthHeight {
        width: 100%;
        height: auto;
    }
    .singleImageWidthHeight {
        width: auto;
        height: 200px;
    }

    .title-topic-icon {
        width: 96px;
        height: 96px;
    }
</style>
