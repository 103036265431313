<template>

    <div class="global-search-input">
        <div class="d-flex">
            <v-text-field
                v-model="defaultSearch"
                id="txt_search"
                clearable
                dense
                outlined
                hide-details
                append-icon="mdi-magnify"
                @click:clear="clickedClear"
                @focus="focusGained"
                @click.prevent.stop="focusGained"
                @keydown="keydown"
                @click:append="performSearch"
                autocomplete="off"
                :class="isActiveUser && hasPremiumSubscription ? 'mr-4' : ''"
                :label="getSearchLabel()">
            </v-text-field>
        </div>
        <v-container
            class="search-dropdown elevation-3 white rounded"
            v-click-outside="clickedOutside"
            v-show="showSuggestions">
            <template v-if="defaultSearch">
                <ai-suggest
                :search="defaultSearch"
                :premiumUser="activeUserHasSubscription"
                ></ai-suggest>
                <v-divider></v-divider>
                <template v-if="activeUserHasSubscription">
                    <search-list
                        title="Content"
                        :search="defaultSearch"
                        :results="contentResults"
                        :loading="loading.contentResults"
                        icon="mdi-magnify"
                        content-key="search"
                        @itemClick="() => this.showSuggestions = false"
                        @setSearchTerm="(term) => defaultSearch = term">
                    </search-list>
                    <v-divider>
                    </v-divider>
                </template>
                <search-list
                    title="Titles"
                    :search="defaultSearch"
                    :results="titleResults"
                    :loading="loading.titleResults"
                    :category="category"
                    show-upsell
                    @itemClick="() => this.showSuggestions = false">
                </search-list>
            </template>
            <template v-else>
                <ai-suggest
                :premium="activeUserHasSubscription"
                ></ai-suggest>
                <v-divider></v-divider>
                <template v-if="isActiveUser">
                    <search-list
                        v-if="activeUserHasSubscription"
                        title="Recent Searches"
                        :results="recentSearches"
                        :loading="loading.recentSearches"
                        icon="mdi-history"
                        content-key="recentSearches"
                        @itemClick="() => this.showSuggestions = false"
                        @setSearchTerm="(term) => defaultSearch = term">
                    </search-list>
                    <v-divider>
                    </v-divider>
                    <search-list
                        title="Recently Viewed Titles"
                        :results="recentlyVisited"
                        :loading="loading.recentlyVisited"
                        show-upsell
                        @itemClick="() => this.showSuggestions = false">
                    </search-list>
                </template>
                <search-list
                    v-else
                    title="Popular Titles"
                    :results="popularTitles"
                    :loading="loading.popularTitles"
                    show-upsell
                    @itemClick="() => this.showSuggestions = false">
                </search-list>
            </template>
            <template>
                <v-divider>
                </v-divider>
                <v-row class="pa-2 justify-left">
                    <span class="ma-1 ml-5 pt-1 text-body-2">Search by:</span>
                    <v-btn v-for="button in searchButtons"
                        v-if="button.isActive"
                        :key="button.name"
                        @click="handleSearchButtonClick(button.id)"
                        depressed
                        class="ma-1 search-btn"
                        small>
                        <v-icon small left>{{ button.icon }}</v-icon>
                        {{ button.name }}
                    </v-btn>
                </v-row>
            </template>
        </v-container>
    </div>
</template>

<script>
import Mobile from "../../inc/mixins/mobile";
import {SuperAxios} from "../../../api/SuperAxios";
import {ErrorHandler} from "../../../classes/ErrorHandler";
import {TitleSearch} from "../../../api/TitleSearch";
import {DateUtil} from "../../../util/DateUtil";
import Constants from "../../../constants";
import SearchBar from "./mixins/searchBar";
import SearchList from "./SearchList.vue";
import AiSuggest from "./AiNavigatorAsk.vue";
import axios from "axios";
import {EventNames} from "../../../classes/EventNames";
import {AppParameters} from "../../../AppParameters";

export default {
    mixins: [Mobile, SearchBar],
    data() {
        return {
            clickedAtLeastOnce: false,
            debouncedGetTitleResults: null,
            debouncedGetContentResults: null,
            showSuggestions: false,
            loading: {
                titleResults: true,
                contentResults: true,
                recentlyVisited: true,
                recentSearches: true,
                popularTitles: true
            },
            contentResults: [],
            titleResults: [],
            category: null,
            popularTitles: [],
            recentlyVisited: [],
            recentSearches: [],
            searchButtons: [
                { id:'titles', name: 'Titles', icon: 'mdi-text-box-multiple-outline', isActive: true },
                { id:'content', name: 'Content', icon: 'mdi-text-search-variant', isActive: true },
                { id:'graphics', name: 'Graphics (Beta)', icon: 'mdi-image-outline', isActive: AppParameters.graphics_search_enabled },
            ]
        }
    },
    computed: {
        currentCategory() {
            // TODO
        },
        currentTitleDocumentId() {
            const documentInfo = this.$store.getters.getDocumentInfo['document_id'];
            return documentInfo ? documentInfo : null
        },
        isPdfDocument() {
            return this.$store.getters.getDocumentInfo['content_type']?.name === Constants.contentTypePDF;
        },
        activeUserHasSubscription(){
            return this.isActiveUser && this.hasPremiumSubscription;
        },
        isChapterPage() {
            const activeChapter = this.$store.getters.getActiveChapter;
            return activeChapter !== 0;
        }
    },
    components: {
        SearchList,
        AiSuggest
    },
    watch: {
        defaultSearch(newValue, oldValue) {
            if (newValue) {
                this.getResults();
            } else {
                this.init()
                // any pending requests should be canceled
                this.debouncedGetTitleResults.cancel()
                this.debouncedGetContentResults.cancel();
            }
        }
    },
    methods: {
        init() {
            if (!this.isActiveUser && !this.defaultSearch) {
                this.getPopularTitles()
            }
            if (this.isActiveUser && !this.defaultSearch) {
                this.getRecentlyVisited()
                if (this.activeUserHasSubscription) {
                    this.getRecentSearches()
                }
            }
        },
        getPopularTitles() {
            this.$set(this.loading, 'popularTitles', true)
            SuperAxios.getCached(Routing.generate('statistics_popular_titles'), null, DateUtil.SECONDS_IN_DAY)
                .then((data) => {
                    this.popularTitles = data;
                }).catch(() => {
                ErrorHandler.alert('Failed to load Popular Titles. Try again later.');
            }).finally(() => {
                this.$set(this.loading, 'popularTitles', false)
            })
        },
        getRecentlyVisited() {
            this.$set(this.loading, 'recentlyVisited', true)
            axios.get(Routing.generate('user_recently_visited'), {
                params: {limit: 5}
            }).then((response) => {
                this.recentlyVisited = response.data
            }).catch(e => {
                ErrorHandler.alert('Failed to load Recently Visited titles. Try again later')
            }).finally(() => {
                this.$set(this.loading, 'recentlyVisited', false)
            })
        },
        getRecentSearches() {
            this.$set(this.loading, 'recentSearches', true)
            const queryParams = {
                category: null,
                document: null
            }

            // we inside /content?
            if (this.$store.getters.getIsDocumentPage) {
                queryParams.document = this.$store.getters.getDocumentInfo['document_id']
            }

            axios.get(Routing.generate('get_recent_searches'), {
                params: queryParams
            }).then((response) => {
                this.recentSearches = response.data
            }).catch(() => {
                ErrorHandler.alert("Failed to fetch Recent Searches... please try again later")
            })
            .finally(() => {
                this.$set(this.loading, 'recentSearches', false)
            })
        },
        performSearch: function() {
            if (!this.defaultSearch) {
                return;
            }
            this.clickedEnter();
        },
        clickedClear() {

            // either "titles" or "content"
            const tabName = this.$store.getters.getTabbedSearchType;
            const routeUri = Routing.generate('sections_search_page', {
                'section': tabName,
                'searchTermAny': ''
            });

            // TODO: might find a better way in the future, but this WORKS very well right now
            window.location = routeUri;
        },
        /**
         *
         * @param {Event} event
         */
        clickedOutside(event) {

            /** @type {HTMLElement} */
            const target = event.target;

            const isClearButton = target.tagName === 'BUTTON' && target.classList.contains('mdi-close');
            const isSearchTextField = target.getAttribute('id') === 'txt_search';

            // It's only "outside" if where we clicked was not inside search textfield itself OR clear icon
            if (!(isSearchTextField || isClearButton)) {
                this.showSuggestions = false;
            }
        },
        /**
         *
         * @param {Event} event
         */
        focusGained(event) {

            this.showSuggestions = true;

            if (!this.clickedAtLeastOnce && this.defaultSearch) {
                this.getResults();
            }

            this.clickedAtLeastOnce = true;
        },
        /**
         *
         * @param {KeyboardEvent} event
         */
        keydown(event) {

            if (event.key === "Enter") {
                event.preventDefault();
                this.clickedEnter();
            } else if (event.key === "Escape") {
                this.showSuggestions = false;
            }
        },
        clickedEnter() {

            if (!this.defaultSearch) {
                return;
            }

            this.showSuggestions = false;

            this.$nextTick(() => {

                if (this.searchTab) {
                    this.updateResults(this.searchTab);
                } else {
                    if ((!this.isActiveUser) || (this.isActiveUser && !this.hasPremiumSubscription)) {
                        this.goToRoute('sections_search_page', {
                            'section': 'titles',
                            'searchTermAny': this.defaultSearch
                        });
                    } else {
                        if(this.$store.getters.getIsDocumentPage) {
                            this.setDocumentToSearch();
                        }
                        this.goToRoute('sections_search_page', {
                            'section': 'content',
                            'searchTermAny': this.defaultSearch
                        });
                    }
                }
            });
        },
        updateResults(searchType) {
            if (searchType === this.$getConst('titleSearchType')) {
                EventBus.fire('update-titles-search', this.defaultSearch);
            }
            if (searchType === this.$getConst('contentSearchType')) {
                EventBus.fire('update-content-search-results', this.defaultSearch);
                EventBus.fire('get-relevant-titles-results');
            }
            if (searchType === this.$getConst('graphicsSearchType')) {
                EventBus.fire(EventNames.UPDATE_GRAPHICS_SEARCH, this.defaultSearch);
            }
        },
        getResults() {
            this.debouncedGetTitleResults();
            if (this.hasPremiumSubscription) {
                this.debouncedGetContentResults();
            }
        },
        getTitleResults() {

            if (!this.defaultSearch) {
                return;
            }

            this.$set(this.loading, 'titleResults', true);

            TitleSearch.search(this.defaultSearch).then((results) => {
                this.titleResults = results;
            }).finally(() => {
                this.$set(this.loading, 'titleResults', false);
            });
        },
        getContentResults() {

            if (!this.defaultSearch) {
                return;
            }

            // show 3 blurred results for non-PREMIUM
            const limit = (this.isActiveUser && this.hasPremiumSubscription && this.isMediumAndUp) ? 5 : 3;

            const apiUrl = Routing.generate('search_content', {
                query: this.defaultSearch,
                document: this.currentTitleDocumentId,
                collapse: 'sectionTitleClean',
                limit: 30
            });

            this.$set(this.loading, 'contentResults', true);

            SuperAxios.getOnce(apiUrl).then(response => {
                /** @type {Array} */
                const data = response.data.data;
                this.contentResults = data.slice(0, limit);
            }).catch(ErrorHandler.handle).finally(() => {
                this.$set(this.loading, 'contentResults', false);
            });
        },

        getSearchLabel() {
            const documentInfo = this.$store.getters.getDocumentInfo;
            if (this.$store.getters.getIsDocumentPage &&
                !this.isPdfDocument &&
                Object.keys(documentInfo).length &&
                this.isPremiumSubscribed(documentInfo.sku)) {
                let searchLabel = `Search ${this.$store.getters.getDocumentInfo['book_year']}
                    ${this.$store.getters.getDocumentInfo['title']}`;
                return searchLabel;
            } else {
                return 'Search Digital Codes';
            }
        },

        handleSearchButtonClick(searchType) {
            this.goToRoute('sections_search_page', {
                'section': searchType,
                'searchTermAny': this.defaultSearch
            });
        }
    },
    created() {

        const titleResultsDelay = TitleSearch.isFastSearchEnabled() ? 100 : 400;

        this.debouncedGetTitleResults = _.debounce(this.getTitleResults, titleResultsDelay);
        this.debouncedGetContentResults = _.debounce(this.getContentResults, 420);

        Mousetrap.bind('esc', () => {
            this.showSuggestions = false;
        });

        EventBus.listen('set-dropdown-category', (category) => this.category = category);

        // TODO: must be less ugly way - sticky chapter info banner cannot be "overlapped" if it has position:fixed
        this.$watch(() => this.showSuggestions, (newValue) => {

            // this only has position:fixed on overview page
            const stickyChapterInfo = document.getElementById('sticky-chapter-info');

            if (stickyChapterInfo) {
                if (newValue) {
                    stickyChapterInfo.style.position = 'static';
                } else {
                    stickyChapterInfo.style.position = 'fixed';
                }
            }

        });
    },
    mounted() {
        this.init()
        EventBus.listen('set-default-search', (searchTerm) => this.defaultSearch = searchTerm)
    }
}
</script>

<style lang="scss" scoped>
.global-search-input {
    position: relative;
    width: 100%;
    max-width: 640px;
}
.search-dropdown {
  position: absolute;
  width: 97.5%;
  margin-top: 0.6em;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 !important;
  overscroll-behavior: contain;
}
.search-btn {
  background-color: #f5f5f5;
  margin: 0 8px;
  border: none;
  box-shadow: none;
  min-width: 120px;
  text-transform: none;
}
.search-btn:hover {
  background-color: #e0e0e0;
}
.adv-search-btn {
    height: 39px !important;
}
@media (max-width: 960px) {
  .search-dropdown {
    max-height: 50vh !important;
  }
}

</style>
