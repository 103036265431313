var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c(
        "v-row",
        { attrs: { dense: "", align: "end" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("p", { staticClass: "caption text-left mb-0" }, [
                _vm._v("Quantity"),
              ]),
              _vm._v(" "),
              _c("v-text-field", {
                staticClass: "mt-1",
                attrs: {
                  "aria-label": "Item Quantity",
                  dense: "",
                  outlined: "",
                  "hide-details": "",
                  readonly: "",
                  value: _vm.item.quantity,
                  type: "number",
                  min: 1,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-1 mb-1",
                  attrs: {
                    fab: "",
                    "x-small": "",
                    role: "button",
                    "aria-label": "Decrement icon button",
                    depressed: "",
                    dark: _vm.item.quantity > 1,
                    disabled: _vm.item.quantity === 1,
                    color: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleChange("decrement")
                    },
                  },
                },
                [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("remove")])],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mx-1 mb-1",
                  attrs: {
                    fab: "",
                    dark: "",
                    "x-small": "",
                    role: "button",
                    "aria-label": "Increment icon button",
                    depressed: "",
                    color: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleChange("increment")
                    },
                  },
                },
                [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("add")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }