<template>
    <footer class="primary">

        <div class="container py-8 white--text" style="max-width: 1400px;">

            <div class="footer-top d-lg-flex justify-space-between align-center">

                <div class="logo-container d-flex">
                    <v-img
                            max-width="80px"
                            src="/app/images/icons/logo.png"
                            alt="ICC logo"
                            role="img"
                    ></v-img>
                </div>

                <div class="mt-lg-0 mt-3 text-lg-center">
                    <p>Subscribe to the Building Safety Journal</p>
                    <v-btn large class="px-12"
                           href="https://www.iccsafe.org/about/periodicals-and-newsroom/subscribe/"
                           target="_blank">
                        Subscribe
                    </v-btn>
                </div>

                <div class="d-flex mt-lg-0 mt-3">

                    <img
                            class="cp mr-5"
                            @click="appClick()"
                            style="max-width:180px"
                            alt="Available on Google Play"
                            @keydown.enter="appClick()"
                            @keydown.space="appClick()"
                            role="link"
                            aria-label="Google Play"
                            tabindex="0"
                            src="/app/images/google_play.png">

                    <img
                            class="cp"
                            @click="appClick(true)"
                            style="max-width:180px"
                            alt="Available on App Store"
                            @keydown.enter="appClick(true)"
                            @keydown.space="appClick(true)"
                            role="link"
                            aria-label="App Store"
                            tabindex="0"
                            src="/app/images/apple_store.png">

                </div>

            </div>

            <ul class="list-none pl-0 footer-links d-lg-flex justify-space-between my-8">

                <li class="footer-solutions">

                    <h6 class="caption mb-0 white--text mb-3">
                        ICC Family of Solutions
                    </h6>

                    <ul class="list-none d-flex align-center mx-0 px-0">
                        <li
                                class="mr-5"
                                v-for="(solution, index) in solutions"
                                :key="index">
                            <a
                                    :href="solution.link"
                                    target="_blank"
                                    :aria-label="ariaLabelForUrl(solution.link)"
                                    :title="solution.title">
                                <img
                                        :src="solution.image"
                                        :alt="solution.title">
                            </a>

                        </li>
                    </ul>
                </li>

                <li class="footer-social mt-lg-0 mt-3">

                    <h6 class="caption mb-0 white--text mb-3">
                        Connect With Us
                    </h6>

                    <ul class="list-none d-flex mx-0 px-0">
                        <li
                                class="mr-5"
                                v-for="(item, index) in socialMedia"
                                :key="index">
                            <a
                                    :href="item.link"
                                    target="_blank"
                                    :aria-label="ariaLabelForUrl(item.link)">
                                <v-icon color="white">
                                    {{ item.icon }}
                                </v-icon>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>

            <div class="footer-links mt-5">

                <ul class="d-lg-flex mx-0 px-0" style="list-style-type: none;">
                    <li
                            class="px-0"
                            v-for="(link, index) in logoLinks"
                            :key="index">
                        <a
                                class="caption white--text"
                                target="_blank"
                                :aria-label="ariaLabelForUrl(link.link)"
                                :href="link.link">
                            {{ link.title }}
                        </a>
                        <span v-if="index < logoLinks.length-1" class="px-8 d-none d-lg-inline-block">&#124;</span>
                    </li>
                </ul>
            </div>

            <div class="footer-bottom mt-8">
                <p class="caption white--text mb-0">
                    COPYRIGHT © {{ new Date().getFullYear() }} INTERNATIONAL CODE COUNCIL, INC. ALL RIGHTS RESERVED.
                </p>
            </div>

        </div>
    </footer>
</template>

<script>
    export default {
        data() {
            return {
                seen: false,
                logoLinks: [
                    {
                        title: 'Privacy Policy',
                        link: 'https://www.iccsafe.org/about/icc-online-privacy-policy/',
                    },
                    {
                        title: 'Terms of Use',
                        link: 'https://www.iccsafe.org/about/terms-of-use/'
                    },
                    {
                        title: 'Contact Us',
                        link: 'https://www.iccsafe.org/about/contact-icc/'
                    },
                    {
                        title: 'Accessibility Policy',
                        link: 'https://www.iccsafe.org/content/accessibility/'
                    }
                ],
                solutions: [
                    {
                        title: 'International Code Council',
                        image: '/app/images/footer/icc_logo.svg',
                        link: 'https://www.iccsafe.org/'
                    },
                    {
                        title: 'ICC Evaluation Service',
                        image: '/app/images/icons/es_logo.svg',
                        link: 'https://www.icc-es.org/'
                    },
                    {
                        title: 'International Accreditation Service',
                        image: '/app/images/icons/ias_logo.svg',
                        link: 'https://www.iasonline.org/'
                    },
                    {
                        title: 'General Code',
                        image: '/app/images/footer/general_code.svg',
                        link: 'https://www.generalcode.com'
                    },
                    {
                        title: 'S. K. Ghosh Associates LLC',
                        image: '/app/images/icons/skga_logo.svg',
                        link: 'https://skghoshassociates.com/'
                    },
                    {
                        title: 'ICC NTA',
                        image: '/app/images/icons/nta_logo.svg',
                        link: 'https://www.icc-nta.org/'
                    },
                    {
                        title: 'Alliance for National & Community Resilience',
                        image: '/app/images/footer/alliance.svg',
                        link: 'http://www.resilientalliance.org/'
                    },
                    {
                        title: 'ICC Community Development Solutions',
                        image: '/app/images/footer/cds.svg',
                        link: 'https://www.iccsafe.org/products-and-services/icc-community-development-solutions/'
                    },
                    {
                        title: 'American Legal Publishing',
                        image: 'https://www.iccsafe.org/wp-content/themes/iccsafe/build/images/alp_logo_2023_tm.4650f20a.svg',
                        link: 'https://www.amlegal.com/'
                    }
                ],
                socialMedia: [
                    {
                        icon: 'fa-facebook-square',
                        link: 'https://www.facebook.com/pages/International-Code-Council/122907857740822'
                    },
                    {
                        icon: 'fa-linkedin-square',
                        link: 'https://www.linkedin.com/company/31134'
                    },
                    {
                        icon: 'fa-youtube-play',
                        link: 'https://www.youtube.com/user/ICCMEDIA'
                    },
                    {
                        icon: 'twitter-x',
                        link: 'https://twitter.com/IntlCodeCouncil'
                    },
                    {
                        icon: 'fa-flickr',
                        link: 'https://www.flickr.com/photos/internationalcodecouncil/'
                    }
                ]
            }
        },
        methods: {
            ariaLabelForUrl(url) {
                const host = new URL(url).host;
                return `Link opens ${host} in a new tab`;
            }
        }
    }
</script>

<style scoped lang="scss">
.twitter-x {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE3LjggMTIuNyAyOC43IDBIMjZsLTkuMyAxMUw5IDBILjNsMTEuNSAxNi43TC4zIDMwSDNsMTAtMTEuN0wyMSAzMGg4LjdMMTcuOSAxMi43Wm0tMy41IDQuMUMxNC4xIDE2LjYgNC4zIDIuNiAzLjggMmg0YzQuNyA2LjggMTMuNSAxOS4zIDE4LjMgMjYuMmgtNGwtNy44LTExLjNaIi8+PC9zdmc+);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    color: transparent;
    display: block;
    font-size: 0;
    height: 20px;
    text-decoration: none;
    transition: transform .2s ease-in-out;
    width: 24px;

    align-items: center;
    display: inline-flex;
}
.footer-solutions a {

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 80px;
  }

  display: inline-block;

/*
  width: 80px;
  height: 50px;

  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  display: block;
  font-size: 0;
  margin: 0 auto;
  text-decoration: none;
  transition: transform .2s ease-in-out;*/

  &:hover > img {
    transform: scale(1.1);
  }

}
</style>
