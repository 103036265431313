export default {
    computed: {
        defaultSearch: {
            get() {
                return this.$store.getters.getDefaultSearch;
            },
            set(value) {
                this.$store.commit('setDefaultSearch', value);
            }
        },
        searchTitles: {
            get() {
                return this.$store.getters.getSearchTitles;
            },
            set(value) {
                this.$store.commit('setSearchTitles', value);
            }
        },
        searchYears: {
            get() {
                return this.$store.getters.getSearchYears;
            },
            set(value) {
                this.$store.commit('setSearchYears', value);
            }
        },
        searchCategories: {
            get() {
                return this.$store.getters.getSearchCategories;
            },
            set(value) {
                this.$store.commit('setSearchCategories', value);
            }
        },
        searchTypes: {
            get() {
                return this.$store.getters.getSearchTypes;
            },
            set(value) {
                this.$store.commit('setSearchTypes', value);
            }
        },
        searchSource: {
            get() {
                return this.$store.getters.getSearchSource;
            },
            set(value) {
                this.$store.commit('setSearchSource', value);
            }
        },
        filterPanel: {
            get() {
                return this.$store.getters.getFilterPanel;
            },
            set(value) {
                this.$store.commit('setFilterPanel', value);
            }
        },
        codesPageTitle: {
            get() {
                return this.$store.getters.getCodesPageTitle;
            },
            set(value) {
                this.$store.commit('setCodesPageTitle', value);
            }
        },
        codesPagination: {
            get() {
                return this.$store.getters.getCodesPagination;
            },
            set(value) {
                this.$store.commit('setCodesPagination', value);
            }
        },
        titleSearchCategory: {
            get() {
                return this.$store.getters.getTitleSearchCategory;
            },
            set(value) {
                this.$store.commit('setTitleSearchCategory', value);
            }
        },
        searchTab: {
            get() {
                return this.$store.getters.getTabbedSearchType;
            }
        },
        tabbedSearchValues: {
            get() {
                return this.$store.getters.getTabbedSearchValues;
            }
        },
        tabbedSearchUrl: {
            get() {
                return this.$store.getters.getTabbedSearchUrl;
            }
        }
    }
}
