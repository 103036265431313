var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.message
    ? _c(
        "v-alert",
        {
          staticClass: "align-self-center",
          class: _vm.containerClass,
          attrs: {
            border: "left",
            "colored-border": "",
            elevation: "0",
            type: _vm.notificationType,
            icon: _vm.icon,
            id: "false",
          },
        },
        [
          _c(
            "v-row",
            { attrs: { dense: "", align: "center" } },
            [
              _c("v-col", { staticClass: "py-0" }, [
                _c(
                  "p",
                  { staticClass: "mb-0 black--text alert-message" },
                  [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.message) },
                    }),
                    _vm._v(" "),
                    _vm.notificationType === "error"
                      ? [
                          _vm._v(
                            "\n                    Please try again later or\n                    "
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "text-decoration-underline",
                              attrs: {
                                href: _vm.$getConst("links")["contactSupport"],
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        contact support\n                    "
                              ),
                            ]
                          ),
                          _vm._v(
                            "\n                    if the issue persists.\n\n                "
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                this.hasSlot()
                  ? _c(
                      "div",
                      { staticClass: "mb-0 addon-content" },
                      [_vm._t("default")],
                      2
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.showLink
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            target: _vm.actionButton.target,
                            href: _vm.linkUrl,
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.linkText) +
                              "\n                "
                          ),
                          _vm.actionButton
                            ? _c(
                                "v-icon",
                                { staticClass: "ml-2", attrs: { small: "" } },
                                [_vm._v(_vm._s(_vm.actionButton.icon))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dismissible
                ? _c(
                    "v-col",
                    {
                      staticClass: "py-0 align-self-start",
                      attrs: { cols: "auto" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "black--text",
                          attrs: { icon: "", "x-small": "" },
                          on: { click: _vm.dismiss },
                        },
                        [
                          _c("v-icon", { staticClass: "font-weight-bold" }, [
                            _vm._v("mdi-close"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }