<template>
    <div class="grey lighten-5 container-height">
        <v-container :class="!isMobileAndTablet ? 'algin-center justify-center' : ''">
        <v-row align="center" justify="center">
            <v-col class="d-flex justify-center">
                <v-card flat :width="isMobileAndTablet ? 800 : 500" class="grey lighten-5">
                    <v-card-title class="justify-center">
                        <v-img
                            max-width="250px"
                            height="95px"
                            alt="ICC Digital Codes Premium Enterprise Home"
                            :src="asset(require('../../../images/enterprise/ent-premium-blue-logo.png'), 'default')"
                        />
                    </v-card-title>
                    <v-card-title class="headline font-weight-bold">
                        Sign In
                    </v-card-title>
                    <v-card-subtitle>
                            Welcome  {{ inviteFirstname }}, you have been invited to <br/>
                            join <b>{{ inviteEnterprise }}</b>
                    </v-card-subtitle>
                    <v-card-subtitle class="subtitle-1 font-weight-black">
                        Enter your Email and Password
                    </v-card-subtitle>
                    <v-card-text>
                        <Form
                            :formAction="formAction"
                            :referer="referer"
                            :token="token"
                            :isEnterprise="true"
                            :inviteEmail="inviteEmail"
                        />
                        <h4 class="font-weight-regular text-center">
                            Don't have an account?
                            <a class="text-decoration-underline font-weight-bold" :href="this.getRoute('signup')">Sign Up</a>
                        </h4>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        </v-container>
    </div>
</template>

<script>
    import Form from './LoginForm.vue';
    import Mobile from '../../inc/mixins/mobile';
    export default {
        data() {
            return {
                enterpriseData:
                {
                    inviteFirstname: this.inviteFirstname,
                    inviteEmail: this.inviteEmail,
                    inviteEnterprise: this.inviteEnterprise
                }
            };
        },
        mixins: [Mobile],
        components: {
            Form
        },
        props: {
            formAction: {
                required: false,
                type: String
            },
            referer: {
                required: false,
                type: String
            },
            token: {
                required: true,
                type: String
            },
            inviteFirstname: {
                required: '',
                type: String,
                default: ''
            },
            inviteEmail: {
                required: false,
                type: String,
                default: ''
            },
            inviteEnterprise: {
                required: false,
                type: String,
                default: ''
            }
        }
    }
</script>
